/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Custom Style Starts Here */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App Styles */
.App {
  width: 450px;
  margin: 0 auto;
  text-align: center;
}
/* Modal Styles */
.App .modal {
  display: none;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}
.App .modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
.modal .title {
  font-size: 1.25rem;
  margin-bottom: .5rem;
}
.modal p {
  font-size: .75rem;
  margin: 0 28px 1rem 28px;
  line-height: 175%;
}
.modal li {
  font-size: .75rem;
  margin: 0 28px 1rem 28px;
}
.App .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.App .close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* Cell Styles */
.cell {
  width: 14px;
  height: 14px;
  background: #5E5B52;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  display: block;
  float: left;
}
.cell:hover {
  background: rgb(75, 72, 65);
}
.cell.active {
  background: #F7CB2E;
}
/* Board Styles */
.board {
  width: 450px;
  height: 450px;
  margin: 0 auto;
}
/* Panel Styles */
.Panel {
  width: 448px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FC7A57;
  border: 1px solid #5E5B52;
}
.Panel span {
  width: 20%;
  text-align: center;
  color: #5E5B52;
  font-size: .75rem;
  font-weight: bolder;
  margin: auto, 0;
}
.Panel .button {
  width: 24%;
  margin: 2% 2%;
  border: 1px solid #18A7B5;
  border-radius: 15px;
  padding: .5rem 0;
  background: #18A7B5;
  cursor: pointer;
  color: white;
  box-shadow: 0 5px #7a7a7a;
  font-size: .70rem;
  font-weight: bold;
}
.Panel .button:active {
  background: #008080;
  box-shadow: 0 1px #7a7a7a;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}
/* Header Styles */
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  width: 448px;
  background: #FC7A57;
  border: 1px solid #5E5B52;
}
.header .title {
  width: 100%;
  padding: .5rem;
  margin-top: .25rem;
  font-size: 1.5rem;
  color: #504d46;
}
.header .button {
  width: 8.75%;
  margin: .75rem 2%;
  border: 1px solid #18A7B5;
  border-radius: 15px;
  padding: .5rem;
  background: #18A7B5;
  cursor: pointer;
  box-shadow: 0 4px #7a7a7a;
  color: white;
  font-size: .60rem;
}
.header .button:active {
  background: #008080;
  box-shadow: 0 2px #7a7a7a;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.header .icon {
  width: 11.5%;
  height: 2.5rem;
  margin: .5rem 3%;
  border: 1px solid #18A7B5;
  border-radius: 15px;
  padding: 0 .5rem;
  background: #18A7B5;
  cursor: pointer;
  box-shadow: 0 4px #7a7a7a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .70rem;
  font-weight: bold;
}
.header .icon:active {
  background: #008080;
  box-shadow: 0 2px #7a7a7a;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.header .label {
  width: 35%;
  font-size: .75rem;
  margin-bottom: .5rem;
  color: #504d46;
}
/* Templates Styles */
.Templates {
  width: 448px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: #FC7A57;
  border: 1px solid #5E5B52;
}
.Templates .title {
  font-size: .75rem;
  color: #5E5B52;
  font-weight: bold;
  margin-top: .5rem;
  width: 100%;
}
.Templates .button {
  width: 17%;
  margin: 2% 1%;
  border: 1px solid #18A7B5;
  border-radius: 15px;
  padding: .5rem 0;
  background: #18A7B5;
  cursor: pointer;
  color: white;
  box-shadow: 0 5px #7a7a7a;
}
.Templates .button:active {
  background: #008080;
  box-shadow: 0 1px #7a7a7a;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}
