/* App Styles */
.App {
  width: 450px;
  margin: 0 auto;
  text-align: center;
}
/* Modal Styles */
.App .modal {
  display: none;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}
.App .modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
.modal .title {
  font-size: 1.25rem;
  margin-bottom: .5rem;
}
.modal p {
  font-size: .75rem;
  margin: 0 28px 1rem 28px;
  line-height: 175%;
}
.modal li {
  font-size: .75rem;
  margin: 0 28px 1rem 28px;
}
.App .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.App .close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}